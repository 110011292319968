import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { TextField, TextComponent, ModelMenuSelectorField, ModelAutocompleteField } from '@front/squirtle/'

import CPUtils from '@front/squirtle/utils/CPUtils'

const Row1 = ({ companyContractLabel, generic }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs={4} hidden={!!generic}>
      <TextComponent
        label={I18n.t('contract.id')}
        value={companyContractLabel}
        disabled
      />
    </Grid>
    <Grid item xs={4}>
      <TextField
        name={'name'}
        label={I18n.t('name.label', { count: 1 })}
        required
      />
    </Grid>
    <Grid item xs={1}>
      <TextField
        name={'priority'}
        label={I18n.t('priority.label', { count: 1 })}
        type={'number'}
        emptyValue={'0'}
        InputProps={{
          inputProps: { style: { textAlign: 'center' } }
        }}
        normalize={value => _.min([_.max([value, 0]), 99])}
      />
    </Grid>
    <Grid item xs={3}>
      <ModelAutocompleteField
        name='mode'
        model_name='ridemode'
        label={I18n.t('ride.label', { count: 1 })}
        labelKeys={['name_translated']}
        searchMode='search'
        config={{ sort: ['display_index ASC'] }}
        loadOnMount
        selectFirst
        noneFirst
        noneLabel={I18n.t('all')}
      />
    </Grid>
    <Grid item xs={3}>
      <ModelMenuSelectorField
        name='commercial_formula'
        model_name='commercialformula'
        label={I18n.t('commercialformula.label', { count: 1 })}
        labelKeys={['name']}
        config={{ defaultLimit: 100 }}
        searchMode='search'
        loadOnMount
        noneFirst
        noneLabel={I18n.t('all')}
        selectFirst
      />
    </Grid>
  </Grid >

const Row2 = ({ authorized_formulas }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs={12}>
    <ModelAutocompleteField
        name={'commercial_packages'}
        label={I18n.t('commercialpackage.label', { count: 2 })}
        model_name='commercialpackage'
        labelKeys={['label']}
        matchKeys={['commercialpackage', 'companycontract']}
        searchMode='search'
        searchQuery={'startsWith'}
        autocompleteKey={'name_trkey.fr'}
        config={{
          mutator: cp => ({ ...CPUtils.getTranslatedLabel(cp), ...cp }),
          forced_filter: { authorized_formulas },
          watchers: [authorized_formulas],
        }}
        selectFirst={false}
        loadOnMount
        multiple
        required
      />
    </Grid>
  </Grid>

const PrimarySection = props => <>
  <StepLabel>
    <Typography variant='h5'> {I18n.t('info.general')}</Typography>
  </StepLabel>
  <StepContent >
    <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
      <Grid container rowSpacing={2}>
        <Row1 {...props} />
        <Row2 {...props} />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(PrimarySection)
