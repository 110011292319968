import React, { useMemo } from 'react'
import { I18n } from '@front/volcanion/'
import { useTheme } from '@mui/styles'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { TextField, SingleOptionSelectorField } from '@front/squirtle/'

import { isPositive } from '@front/squirtle/utils/validators'

const Row1 = props => {
  const theme = useMemo(() => useTheme(), [])
  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs>
        <SingleOptionSelectorField
          name={'billing_criteria.check_manual_dispatch'}
          label={I18n.t('billingcriteria.check_manual_dispatch')}
          clearOnEmpty={false}
          getOptionLabelProps={() => ({
            disableTypography: false,
            componentsProps: { typography: { fontSize: 12, color: `${theme.palette.text.label} !important` } }
          })}
        />
      </Grid>
      <Grid item xs>
        <TextField
          name='correction_minute_rate'
          label={I18n.t('billingcriteria.correction_minute_rate')}
          type={'number'}
          validate={isPositive}
          InputProps={{
            endAdornment: I18n.t('fiat.eur.sign'),
            inputProps: { style: { textAlign: 'right' } }
          }}
        />
      </Grid>
      <Grid item xs>
        <TextField
          name='billing_criteria.base_max'
          label={I18n.t('billingcriteria.base.max')}
          type={'number'}
          validate={isPositive}
          InputProps={{
            endAdornment: I18n.t('fiat.eur.sign'),
            inputProps: { style: { textAlign: 'right' } }
          }}
        />
      </Grid>
      <Grid item xs>
        <TextField
          name='billing_criteria.base_margin'
          label={I18n.t('billingcriteria.base.margin')}
          type={'number'}
          validate={isPositive}
          InputProps={{
            endAdornment: I18n.t('prefix.percent'),
            inputProps: { style: { textAlign: 'right' } }
          }}
        />
      </Grid>
      <Grid item xs>
        <TextField
          name='billing_criteria.handling_max'
          label={I18n.t('billingcriteria.handling_max')}
          type={'number'}
          validate={isPositive}
          InputProps={{
            endAdornment: I18n.t('fiat.eur.sign'),
            inputProps: { style: { textAlign: 'right' } }
          }}
        />
      </Grid>
      <Grid item xs>
        <TextField
          name='billing_criteria.distance_max'
          label={I18n.t('billingcriteria.distance_max')}
          type={'number'}
          validate={isPositive}
          InputProps={{
            endAdornment: I18n.t('prefix.m'),
            inputProps: { style: { textAlign: 'right' } }
          }}
        />
      </Grid>
      <Grid item xs>
        <TextField
          name='billing_criteria.estimation_offset'
          label={I18n.t('billingcriteria.estimation_offset')}
          type={'number'}
          validate={isPositive}
          InputProps={{
            endAdornment: I18n.t('fiat.eur.sign'),
            inputProps: { style: { textAlign: 'right' } }
          }}
        />
      </Grid>
    </Grid>
  )
}


const LateCancel = props => <>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('billing.criteria')} </Typography>
  </StepLabel>
  <StepContent >
    <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
      <Grid container rowSpacing={2}>
        <Row1 {...props} />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(LateCancel)
